import { clsx } from 'clsx';
import { brandContainer, content, mask, subTitle, title } from './brand.css.ts';
import { contentBlock } from './main-waitlist.css.ts';
import { PropsWithChildren } from 'react';

export const Brand = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx(contentBlock, brandContainer)}>
      <div className={content}>
        <p className={title}>Community-driven</p>
        <p className={clsx(title, mask)}>intelligence network</p>
        <p className={subTitle}>Share, analyze, and compete</p>
      </div>
      {children}
    </div>
  );
};
